import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const TermsAndConditionsRider = () => {
  return (
    <section className='px-5 h-auto w-screen Poppins flex flex-col items-center bg-[#fafae0]'>
      <Navbar />
      <div className='pt-[120px] flex justify-center'>
        <section className='lg:w-[80%] w-full lg:p-7 px-5 py-4 bg-white rounded-xl border-2 border-accentYellow'>
          <h1 className='lg:text-2xl text-xl font-bold mb-4 text-center'>TERMS AND CONDITIONS RIDER</h1>
          <p className='pb-2'>This delivery via Instaport The "INSTAPORT DELIVERY EXECUTIVE Partner" Terms of Use regulate how you may access and use the information service platform Platform through our websites, mobile applications, and internet applications (collectively, "Applications") in order to receive services that the Instaport delivery service offers.</p>
          <p className='pb-2'>Knowing these terms, you hereby confirm that you have read them and will abide by them, that you are of legal age to enter into contracts, and that you have the authority to and the capacity to agree to these terms.</p>
          <p className='pb-2'>These Terms are subject to change at any moment by Instaport delivery, unless it notifies you differently. Any changes will take effect as soon as they are posted. Your continuing utilization of the Service or Platform following any such Your consent to be bound by such adjustments will be indicated by such revisions. Well, it is It is your obligation to constantly read the Terms in order to keep updated. Should any changes are changed, Instaport delivery will update the date at the top of the Terms to reflect the new information.</p>
          <p className='pb-2'>You may receive further notification from Instaport delivery in some circumstances.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>INSTAPORT DELIVERY EXECUTIVE PARTNER ACCOUNT</h1>
          <p className='pb-2'>Registration for an INSTAPORT DELIVERY EXECUTIVE Partner Account with Instaport delivery is necessary before you can use the Services. You will be given access to an INSTAPORT DELIVERY EXECUTIVE Partner once your registration with Instaport Delivery is successfully completed.</p>
          <p className='pb-2'>In compliance with instaport Delivery's privacy policy, you must supply your personal information during registration, including your name, photo, and contact details, either directly or indirectly. You acknowledge that the information you have entered and are keeping is true, comprehensive, and legitimate. Delivery of Instaport is not responsible for inaccurate, outdated, or inaccurate Your failure to access and utilize the Services may be caused by inaccurate, full, and valid information. Any behavior that takes place under your INSTAPORT DELIVERY executive partner Account is entirely your responsibility. It is within Instaport Delivery's rights to confirm the accuracy of any information you submit. times and decline to use the Services without giving a reason or giving a warning beforehand.registration details that you supplied. Your inability to access or continue</p>
          <p className='pb-2'>In order to access and use the Services, you are in charge of purchasing and maintaining any suitable hardware or devices. The Services, or any part of them, may not work on any specific hardware or devices, even with Instaport delivery.</p>
          <p className='pb-2'>If you are utilizing the Services, Instaport Delivery has the right to stop using them at any time. the security of your instaport, or the Service on an unapproved or incompatible device.Partner Account has been compromised for whatever reason we may determine to be legitimate</p>
          <p className='pb-2'>By using the Services, you consent to the following terms: you will only access the Services through channels specifically approved by Instaport Delivery; the platform and Services will be used exclusively by you for your own use only, with no intention of reselling to others Your INSTAPORT DELIVERY EXECUTIVE Partner Account will not be accessible to third parties One INSTAPORT DELIVERY EXECUTIVE Partner Account may only be opened by you;</p>
          <p className='pb-2'>You are not permitted to use an account that is subject to someone else's rights. Without the necessary authorization; the Services may not be used for illegal activities, such as breaking applicable laws, storing or transmitting any illegal materials, or sharing sensitive personal information of others without that person's consent in a way that would intentionally cause harm, nuisance, inconvenience, or annoyance, or that would impair or impairing the Services' ability to function properly and displaying the tone of pretending to be someone else, or duplicating or sharing the Services without approval for Instaport delivery.</p>
          <p className='pb-2'>You should keep your password and any identifying credentials that Instaport delivery gives you private and safe. If you need to submit additional documentation or evidence of identity right away, As required by Instaport delivery, adherence to all applicable laws is essential.</p>
          <p className='pb-2'>"Applicable Law" refers to applicable Indian laws, rules, and regulations when utilizing the Services. Regarding guidelines controlling remuneration for all services rendered under this agreement, encompassing all international laws, treaties, guidelines, directives, and regulatory recommendations, guidelines, rules, decrees, or decisions made by (or in accordance with), and required correspondence from (or agreements with) any regulatory authority, since each of the aforementioned could change and become effective at any time moment.</p>
          <p className='pb-2'>You may not access, acquire, copy, or monitor any section of the content by any "deep-link," "page-scrape," "robot," "spider," or other automated device, program, algorithm, or methodology, or any such or equivalent manual process.</p>
          <p className='pb-2'>Platform or any material, or replicate or evade the navigational arrangement or display of the Platform or any content, in order to acquire or make an effort to acquire any resources, records, or data using any unintentional made accessible via the Platform. The right of Instaport Delivery to prohibit any such a task You will not try to tamper with, undermine the integrity of the system, or security or interpret any communications sent to or received from the systems hosting the platform; you are not allowed to gather or harvest any personally identifying information from the platform, including account names; and You are not allowed to use intimidation, harassment, or discrimination (on the basis of race, gender, age, handicap, or any other category that is protected) or any other actions while using the Services that Instaport delivery considers unacceptable.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>DISCUSSION OF SERVICES</h1>
          <p className='pb-2'>The purpose of Instaport delivery's services, which it refers to as "Services", is to link those in need of logistical and/or transportation services, such as small enterprises, commercial clients, and individual clients (collectively, "Users"). additionally ASSISTANT DELIVERY MANAGER Partners that offer logistical and/or transportation services Shipment Delivery using the Platform.  You understand and consent to the fact that Instaport delivery only offers you Providing Users with impartial, independent information matching services provided by third parties.</p>
          <p className='pb-2'>The INSTAPORT DELIVERY EXECUTIVE is in charge of shipment delivery. Instaport delivery won't be considered a provider of partners.</p>
          <p className='pb-2'>Delivery Instaport Delivery disclaims all duty with regard to the Shipment Delivery and is not liable for the actions or inactions of any of its executive partners.</p>
          <p className='pb-2'>You declare that you have an autonomous relationship with Instaport delivery, not employment and worker relations. Furthermore, no collaboration, alliance, there is a relationship of employment or agency between the INSTAPORT DELIVERY EXECUTIVE Instaport delivery as a partner and as a result of using the or these Terms Platform. In the event that an INSTAPORT DELIVERY EXECUTIVE Partner decides to wear a uniform or makes use of an Instaport delivery sign, sticker, or identity badge, they acknowledge that this is a personal choice and is not mandated by Instaport delivery. and the executive for in-state delivery Partner understands that using any kind of label, sticker, or name badge does not imply that the person is an employee.</p>

          <h1 className='text-xl font-semibold mt-4 self-start uppercase'>Indemnification</h1>
          <p className='pb-2'>The INSTAPORT DELIVERY EXECUTIVE Partner understands that they bear full responsibility for all shipments and all Delivery of Shipments while in route to destinations. ASSISTANT DELIVERY MANAGER Partner acknowledges that Instaport shipping has lack authority or accountability over shipments or cargo delivery, and solely possesses control or accountability for the Platform's operation. ASSISTANT DELIVERY MANAGER Partner certifies that delivery via Instaport shall not be held accountable or liable for any deeds, misconduct, losses, or damages by the INSTAPORT DELIVERY EXECUTIVE Partner, unless it constitutes a deliberate violation of these terms or a flagrant heedlessness.</p>
          <p className='pb-2'>ASSISTANT DELIVERY MANAGER Partner undertakes to protect, defend, and keep harmless Instaport delivery, as well as its officers, owners, employees, and agents (collectively, "Instaport delivery Indemnitee"), as well as its past and current successors, assigns, affiliates, and other parties from any and all losses, real or threatened. lawsuits, legal actions, court cases, claims, damages, and payment claims,defects, penalties, rulings, awards, agreements, obligations, charges, and expenditures (include) reasonable legal fees, expenses, fines, interest, and settlements resulting from or expended in relation to third-party claims made against an Instaport delivery</p>
          <p className='pb-2'>An indemnitee resulting from, resulting from, or connected with an instaport delivery executive Partner's violation of these terms regarding their behavior on the Platform, Services, and/or a suspected breach of any law or the rights of, or a Shipment Delivery violation any third party, such as users, drivers, other INSTAPORT DELIVERY EXECUTIVE Partners, and so on pedestrians, assigns, subcontractors, and employees disclosure of "Confidential Details regarding the ownership, use, and functioning of any vehicle utilized in the shipment</p>
          <p className='pb-2'>Damages resulting from, resulting from, or connected with the employment of an in-transit delivery executive A partner who violates these terms of behavior regarding the Platform, Services, and/or Shipment Delivery Violation, or any purported breach of any legal statute or the entitlements of any third party, including but not limited to users, drivers, and other INSTAPORT DELIVERY EXECUTIVE Partners revelation of "Confidential" information by workers, subcontractors, assigns, and passersby Details on who owns, uses, or operates any vehicle utilized in the shipment Lack of appropriate insurance, licenses, authorizations, or permits for delivery any losses or damages resulting from the executive delivery of exports Partner's actions, behavior, and/or instaport delivery and nonpayment of all taxes, or claimed nonpayment of taxes, withholding insurance premiums or contributions. In the context of the withholding premiums or contributions for insurance. Instaport Delivery shall have the right to employ its own legal counsel as part of the INSTAPORT DELIVERY EXECUTIVE Partner's duty of defense.</p>
          <p className='pb-2'>In addition to agreeing to execute Shipment Delivery in accordance with all applicable laws, INSTAPORT DELIVERY EXECUTIVE Partner shall defend, indemnify, and keep harmless Instaport delivery for any alleged or actual violations of any such laws by INSTAPORT DELIVERY EXECUTIVE Partner. Reserves for Instaport deliveries the authority to block, halt, or remove an INSTAPORT DELIVERY EXECUTIVE Partner's access to the Platform at any time breaches of these terms or any other behavior that Instaport delivery deems unacceptable. Within the uncommon circumstance in which INSTAPORT DELIVERY EXECUTIVE When a court, agency, or arbitrator rules that the partner is not an independent contractor and the partner's position as an independent contractor is contested, ASSISTANT DELIVERY MANAGER Partner undertakes to protect, hold blameless, and reimburse Instaport delivery for any expenses. `n fines, overdue taxes, compensatory damages, and legal costs related to that decision. EXECUTIVE FOR INSTAPORT DELIVERY As part of its defense obligation, Instaport Delivery is allowed to retain its own legal representation.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>Defining Terms</h1>
          <p className=''>The conditions and terms listed below, which control this contract of carriage between you and us, are defined as follows:</p>
          <ul className='pl-3 pb-2' >
            <li>•	When referring to INSTAPORT DELIVERY SERVICE, INSTAPORT means its staff members and its service providers who either carry or agree to transport the shipment under this agreement or handle any other ancillary services on its behalf.</li>
            <li>• The terms "you", "you're", and "shipment" refer to the person sending the package, the shipment or consignee, the owner of this shipment note, the recipient, the owner of the contents of the shipment, and any other party with a legitimate interest in those contents, as applicable.</li>
          </ul>

          <p className=''>The following are made possible by the services that Instaport offers:</p>
          <ul className='pl-3' >
            <li>•	"Delivery" refers to the act of presenting the package to the recipient or notifying them of its arrival.</li>
            <li>•	"Restricted Items" are any products or materials that the applicable law forbids the carriage of.</li>
            <li>•	The term "Consignee" or "Receiver" designates the shipment's consignee or recipient, addressee, or both.</li>
            <li>•	All laws, statutes, ordinances, regulations, guidelines, policies, rules, bye-laws, notifications, directions, directives, and orders, as well as any other governmental restrictions, are referred to as "applicable law." This also includes any similar form of decision made by, or determination made by, the government of the Republic of India, as well as any interpretation, administration, and other pronouncements made by, state, municipality, court, tribunal, government, ministry, department, commission, arbitrator, board, or any other body with the authority of law in India.</li>
            <li>• "Dangerous Goods" refers to products that are deemed dangerous by the ICAO T.I., IATA DGR, IMDG-Code, ADR, or other national transportation laws.</li>
            <li>•	The services of doorstep to doorstep goods transportation of automobiles by Customers; o Time-and usage-based services inside cities and between cities throughout India; o Any additional services that Instaport may occasionally provide.</li>
            <li>• "Shipment" refers to any package, package, or sachet that is provided to us and authorized for transportation in accordance with our Shipment Note.</li>
          </ul>

          <h1 className='text-xl font-semibold mt-4 self-start'>SERVICE USAGE TERMS & CONDITIONS</h1>
          <p className=''>Customers that use the company's vehicle rental services are subject to the following terms and conditions: The fare (as negotiated), parking fees, any additional night surcharge that may apply, and any other fees or levies that are currently due or may be imposed in the future by the applicable law must all be paid by the client in order to use Instaport's services. The user understands and agrees that using the company's services is entirely at their risk. The user also agrees that the company makes no express or implied warranties or guarantees of any kind. When using the service, the client agrees to refrain from engaging in any of the following activities:</p>
          <ul className='pl-3 pb-2' >
            <li>•	Containing dirt or harm to the car's body and/or interiors.</li>
            <li>• Abusing, contaminating, or causing harm to any of the technical or non-technical devices in the car.</li>
            <li>• Requesting that the motorist disobey any traffic, RTO, city police, or government regulations for any reason. The driver is free to turn down a customer's request of this kind. Additionally, the driver is free to decline such a pick-up.</li>
            <li>• Driving the cyclist to overload the bike with pressure. (up to 20 kg)</li>
          </ul>
          <p className='pb-2'>When using or proposing to use the Services, the Customer agrees to hold the Company harmless from any and all liabilities, losses, charges, expenses (including legal fees and costs on a full indemnity basis), claims, demands, actions, and proceedings that the Company may face or sustain, directly or indirectly. The Customer also agrees to pay the Company any amounts that may be demanded of it.</p>
          <p className='pb-2'>By using the mobile device to book a car, the customer gives the company permission to utilize the location-based data that any of the telecommunications companies have provided. The only purpose of the location-based data is to increase and facilitate the likelihood of finding the customer.</p>
          <p className='pb-2'>The information that the company has about a customer may be disclosed in any manner that the company, in its sole discretion, thinks appropriate or if it believes it is in its best interests to do so to any government body, other companies in its group, or any government body as required by applicable law or by directive or request from any government body.</p>
          <p className='pb-2'>The Company reserves the right to cancel the customer's vehicle reservation at any time and for any reason. The user(s) agrees to hold Instaport harmless for any costs paid in connection with the booking.</p>
          <p className='pb-2'>If an item is lost in transit, the company will make a "best-effort" to locate it; but, it will not be held accountable for any loss, damage, theft, or misappropriation of the item. Instaport gathers the cars together in order to offer services. Users shall not be entitled to withhold payment from Instaport in the event that any item is lost. Furthermore, Instaport retains the right, in compliance with the Applicable Law, to exercise a specific lien over the shipment until full payment for the Services is made to Instaport. This right arises in the event that any payments from the User to Instaport are overdue for the duration specified by Instaport in its corresponding invoice or statement. Furthermore, User(s) agree to hold Instaport harmless from any loss, harm, or costs resulting from the shipment's possession during this time.</p>
          <p className='pb-2'>Within twenty-four hours of utilizing the company's vehicles or services, the customer must notify the company in writing of any complaints regarding the services or the usage of the vehicles.</p>
          <p className='pb-2'>Regarding the behavior of the drivers of the vehicles, the Company shall not be held responsible. Nonetheless, the Company requests that you inform it of any grievances you may have regarding the driver you may have employed through the Company's services.</p>
          <p className='pb-2'>Anytime the Company decides to add to, change, or modify any of these terms and conditions, the Customer will be agree to be bound by any addition, alteration, or amendment after it is incorporated into these terms and conditions at <a href="https://instaportdelivery.com">https://instaportdelivery.com</a>, the company's website, on the day that the company may specify that it is to go into effect.</p>
          <p className='pb-2'>For quality and training purposes, the company records every call made to its call center. If you post a question on our app or website about our services, fees, or terms of service, you hereby specifically consent to receiving our answers to your question and any related information about our services, whether they come in the form of phone calls or emails. Any marketing and/or commercial information is, without limitation, considered connected material for the purpose of dispelling any doubts. You comprehend, concur, and recognize that the publication of such information does not, in any case, qualify as unsolicited commercial communication under the Telecom Unsolicited Commercial Communications Regulations, 2007. our phone numbers will not be eligible for registration under the Telecom Unsolicited Commercial Communications Regulations, 2007 or any other applicable law's "National Do Not Call Register" or "Private Do Not Call Register."</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>RIGHTS TO INTELLECTUAL PROPERTY</h1>
          <p className='pb-2'>All rights to the website and its content are owned solely by Instaport Delivery or are lawfully licensed to them. The term "website content" refers to the layout, text, pictures, sound, and other elements of a website. Trade secrets and intellectual property rights covered by international copyright and other laws are included in the content of this website. All ownership, title, and intellectual property rights in the website and its contents will continue to belong to Instaport delivery, its affiliates, or, where applicable, the licensors of Instaport delivery content.</p>
          <p className='pb-2'>All rights are hereby reserved, unless expressly stated otherwise in this agreement or by Instaport delivery.in. The reader assumes all responsibility for the use of the content on this website, which is only meant to offer broad information for their own use. The quality of any goods, information, or other materials displayed, or obtained by you as a result of an advertisement or any other information or offer in or in connection with the Service, is not represented or endorsed by Instaport delivery, nor is it implied by any information or advertisements (collectively, the "content") contained on, distributed through, linked, downloaded, or accessed from any of the Services contained on this website. We disclaim all liability for any mistakes or omissions in this information, as well as for the outcomes of using it. All content on this website is provided "AS IS," meaning that there is no guarantee regarding its timeliness, completeness, accuracy, or usefulness, nor any implied or express warranties of any kind, including but not limited to merchantability, fitness for a particular purpose, and performance. Nothing in this article is intended to replace the user(s)' independent research and solid technical and commercial judgment in any way. Instaport Delivery shall not be responsible for any direct, indirect, any type of incidental, punitive, or consequential damages in relation to the Service. The user(s) of this site agree to assume all liability for any reliance on any content.</p>
          <p className='pb-2'>All associated icons and logos are protected by relevant copyright, trademark, and other proprietary rights laws and are registered trademarks, trademarks, or service marks of Instaport delivery in different regions. It is expressly forbidden to use, modify, copy, or publish these marks without authorization. Original</p>
          <p className='pb-2'>Any part of the Service or the app/website may be improved upon, or any error or omission may be corrected, at Instaport delivery's sole discretion and without any obligation. Brand Name</p>
          <p className='pb-2'>All information on this website, with the exception of links to third-party websites on our app or website, is copyrighted by Instaport Delivery.</p>
          <p className='pb-2'>Without express authorization from Instaport delivery, it is forbidden to use robots, spiders, automated devices, or manual processes to retrieve content from Instaport delivery in a way that results in the creation or compilation of a collection, compilation, database, or directory, either directly or indirectly.</p>
          <p className='pb-2'>Furthermore, using the content for any purpose that isn't specifically allowed under this Agreement is forbidden and could result in legal action. You acknowledge that using the website service to violate Instaport delivery's or any other third party's intellectual property rights is a requirement for accessing and using the services provided by Instaport delivery. If a user violates the rights of any third party while using the Instaport delivery service, or if Instaport delivery feels that the user's behavior is detrimental to the company's interests, Instaport delivery has the right to terminate the user's account. its affiliates, other users, or for any other reason in the sole discretion of Instaport delivery, with or without justification. Without affecting Instaport delivery's ability to pursue legal action against you, you agree to compensate Instaport delivery for any losses or costs incurred by Instaport delivery as a result of any violation of Instaport delivery's intellectual property rights.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>TERMINATION </h1>
          <p className='pb-2'>The majority of the content and a few features on the website are provided without charge to users. On the other hand, Instaport delivery retains the right, at any time and for any reason, to discontinue access to any part of the website, with or without prior notice. In order to safeguard the interests of Instaport delivery and/or other website users, the company additionally maintains the absolute right to refuse access to any or all of its services to certain users without providing previous warning or an explanation. With regard to various user(s), Instaport delivery maintains the right to restrict, refuse, or grant access to the website and its services in different ways. It also has the right to modify or add new features at any time.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>AGREEING WITH OUR TERMS AND CONDITIONS </h1>
          <p className='pb-2'>Regardless of whether you have signed the front of our consignment note or not, by giving us your consignment, you accept our terms and conditions as stated in the consignment note, the contract of carriage, and/or the contract for the performance of other services on behalf of yourself and/or anyone else who has an interest in the consignment or the performance of other Services. In addition to our employees, directors, and agents, our terms and conditions also apply to anyone we engage or subcontract to pick up, transport, deliver, or carry out other services. Any written change to these terms and conditions requires the approval of one of our authorized officers only. We will not be obligated to follow any written or verbal instructions that you give us with the shipment that go against our terms and conditions.</p>
          <p className=''>DUTY YOU AGREE TO</p>
          <ul className='pl-3 pb-2' >
            <li>•	You promise, warrant, and guarantee the following to us: o The contents of the shipment (weight and quantity of items included, for example) have been accurately described on our consignment note; the consignment note is complete in every way; and the documents necessary for the shipment's transportation, such as the invoice and permits, are enclosed with the consignment note.</li>
            <li>•	That neither you nor the consignee are a person or organization with whom we or you may not lawfully trade under Applicable Law, and that the contents of the shipment are not Prohibited Items and/or are not restricted by the applicable regulations. o That you will provide to us any necessary declaration of dangerous goods in accordance with Applicable Law, correctly and accurately.</li>
            <li>•	It is expressly agreed that we will be conclusively believed to have delivered the products in line with this contract, and we are authorized to deliver the goods at the address specified on the Consignment Note. We will use emails and SMS to confirm delivery; if we don't hear back from you within a day, it will be assumed that the delivery was successful.</li>
            <li>•	You have accurately stated the weight of the shipment, and you will supply any specialized tools we might require to load or unload the shipment into or out of our cars.</li>
            <li>•You will quickly settle our invoice in full within 7 days of us issuing you the invoice, along with an administration fee, if you have asked us to charge the recipient or a third party and they do not pay us. o You have followed with applicable law.</li>
            <li>•That all of the information and documentation you provide about the consignment will be true and accurate. You also understand that if you provide false or fraudulent information about the consignment or any of its contents, you run the risk of facing civil lawsuits and/or criminal prosecutions, with possible consequences like forfeiture and sale. You undertake to defend and keep us harmless against any claims based on the information you submitted, made against us or our agents.</li>
            <li>•	You comprehend, concur, and acknowledge that before delivering the consignment for transportation, you must obtain an insurance policy for it from a general insurance company that operates in India if the consignment or any of its contents are valued at more than INR 20,000/- (Indian Rupees Twenty Thousand only). The consignment will be transported at your risk and not ours if there is insufficient insurance to cover loss of goods in transit, if the consignment details or value are incorrectly or intentionally omitted, and whether the consignment is insured or not. We hereby expressly and specifically disclaim any liability and/or responsibility arising from any damage, loss, theft, misappropriation, or any insurable loss to / of the consignment or any contents of the consignment. You also agree to us in any instances when your activities in violating our terms of service have caused us to lose goodwill or reputation.</li>
            <li>•You recognize, agree, and understand that the Services are not appropriate for the transportation of valuables, such as cash, gold, silver, diamonds, precious stones, jewelry, expensive luxury goods, etc. When you hand over or load a vehicle containing valuables for transportation, you assume all risk of loss, damage, theft, or misappropriation of the consignment. For the previously stated reasons, and without affecting the other provisions, we reserve the right to explicitly and specifically disclaim any liability and/or responsibility arising from any loss, damage, theft, misappropriation, or any of the consignment's contents, as well as the right to seek indemnification from you in cases where your actions have caused us to lose goodwill or reputation.</li>
            <li>•To the fullest extent permitted by applicable law, the customer agrees and acknowledges that using the services provided by the company is at their own risk and that the company disclaims all representations and warranties of any kind, whether express or implied, regarding condition, suitability, quality, merchantability, and fitness for any particular purpose. Notwithstanding the foregoing, the Company disclaims all liability and makes no representations regarding: o The Services fulfilling the needs of the customer.</li>
            <li>•	The Services will be prompt, secure, error-free, and uninterrupted.</li>
            <li>•You attest that all of the statements and details you supply regarding the consignment's transportation will be accurate and truthful. You understand that you run the danger of facing civil lawsuits and/or criminal charges, with the confiscation and sale of your consignment as punishment, if you make false or fraudulent statements concerning the consignment or any of its contents. If we choose to help you with the necessary paperwork for customs and other formalities, that help will be provided at your own risk. You also agree to reimburse us for any expenses we may incur as a result of the information you give us, to hold us free from any claims made against us, and to cover any administrative fees. that we might impose on you in exchange for supplying the services detailed in this clause.</li>
            <li>•	Any responsibility for any damages, including but not limited to direct, indirect, incidental, punitive, and consequential damages, resulting from the use of the company's service.</li>
            <li>•	Any other or additional fees, including tolls, for remote places.</li>
            <li>•	Any liability or responsibility for any loss or damage, as a result of using the services provided by the company, or resulting from the company's failure to provide the services to the customer for any reason at all, including but not limited to the customer's failure to follow the voice instructions provided by the services, malfunction, partial or complete failure of any network terminal, data processing system, computer tele-transmission system, or telecommunications system, or other circumstances that may or may not be beyond the company's control or the control of any individual or entity involved in the aforementioned systems for any reason.</li>
            <li>• You have complied with applicable law.</li>
            <p className='pb-2 pl-5'>Events outside of our control, including but not restricted to:  Natural disasters, such as earthquakes, cyclones, storms, flooding, fire, illness, fog, snow, or frost Third-party criminal activities, such as natural tragedies or disaster.</p>
            <p className='pb-2 pl-5'>Force majeure encompasses several events such as war, epidemics, pandemics, accidents, public enemy acts, strikes, embargoes, airborne hazards, local disputes, or civil disturbances.</p>
            <p className='pb-2 pl-5'>Disruptions to air or ground transportation networks on a national or local level, as well as mechanical issues with vehicles or equipment.</p>
            <p className='pb-2 pl-5'>Potential flaws or innate immorality present in the shipment's contents. Arson and heaviness.</p>
            <li>•	Your actions or inactions, or those of third parties, including: Your breaching your responsibilities under these terms and conditions, or any other person claiming an interest in the consignment causing you to breach these terms and conditions.</li>
            <li>•	The contents of the shipment, including any articles that are forbidden, hazardous goods, or valuables, even if you voluntarily gave the shipment to us without alerting, informing, or declaring it to us, or even if we may have accepted it in error.</li>
          </ul>

          <h1 className='text-xl font-semibold mt-4 self-start'>DANGEROUS GOODS / SECURITY </h1>
          <ul className='pl-3'>
            <li className='pb-2'>•	<span className='font-medium'>Illegal Items:</span> Consignments containing forbidden items will not be accepted. Consignments will only be accepted if you declare the kind and value of the consignment (the foundation for the term "said to contain"). Regarding the accuracy of the description, kind, or value of the declaration you provide, we bear no liability whatsoever. You also agree to defend, indemnify, and hold us harmless against any claims arising from or connected to the declaration you provide and any associated expenses..</li>
            <li className='pb-2'>•	We neither carry nor provide any other services in relation to goods that, in our sole opinion, are dangerous goods. These goods may include, but are not limited to, those listed in any laws, rules, technical instructions, or codes that apply to our company, the transportation of dangerous goods, or the provision of other services pertaining to them.</li>
            <li className='pb-2'>•	 If you have been given the status of an approved customer, we may, at our discretion, accept some dangerous goods for transport or for the performance of other services in specific areas. However, this must be confirmed in writing by us before your consignment is accepted. Only if your dangerous goods meet our requirements and the relevant rules will they be allowed. Our local office has information on our requirements and how to apply for approved customer status. Upon acceptance of your consignment, you will receive an invoice with a dangerous goods surcharge.</li>
          </ul>

          <h1 className='text-xl font-semibold mt-4 self-start'>ACTIONS PURSUANT TO THIRD PARTIES </h1>
          <p className='pb-2'>You promise us that, even in the event that we were careless or in default, you will not allow anyone else with an interest in the consignment to file a claim or action against us arising out of the carriage, and that, in the event that such a claim or action is filed, you will indemnify us against the results of the claim or action as well as the costs and expenses we incur in defending it.</p>
          <p className='pb-2'>The terms and conditions of use and/or agreement mentioned above, together with the privacy policy, represent the whole understanding between the user(s) and Instaport delivery regarding the use of the website and the services that Instaport delivery provides. This agreement supersedes any previous written or verbal agreements pertaining to the same.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>DENIED ORDERS</h1>
          <p className='pb-2'>We will make an effort to get in touch with you and, if necessary, agree to the next course of action if the recipient declines to accept delivery. You consent to reimburse us for any expenses we spend for shipping, getting rid of, or returning the package, as well as any fees we assess for the decided-upon proper course of action.</p>
          <p className='pb-2'>In the event that the user(s) cancels their agreement with Instaport delivery regarding any consignment, they will be responsible for paying any fees and other costs incurred in relation to that consignment.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>CONFIDENTIALITY</h1>
          <p className='pb-2'>Any information obtained by User(s) under the terms of the service agreement with Instaport delivery may not be disclosed to any other party. Access to any information pertaining to Instaport Delivery's operations will be kept private to the extent that it could have a negative effect on the company. Any loss of business or reputation resulting from the actions of the user(s) shall be compensated to Instaport delivery by the user(s).</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>DISCLAIMER</h1>
          <p className='pb-2'>For security purposes exclusively, mobile technology is used to continuously track all vehicles registered with the company. It is explicitly stated here that the Company does not own any cars and does not, either directly or indirectly, hire laborers to load or unload cargo or drivers for the vehicles. The Company disclaims any and all liability(ies) with regard to the labor, drivers, and cars combined, as these are provided by third parties.</p>
          <p className='pb-2'>The company is permitted to use the contact details provided by customers for its own marketing initiatives. The mobile numbers registered with the Company may get information via SMS on a regular basis.</p>

          {/* <h1 className='text-xl font-semibold mt-4 self-start'>INSTAPORT DELIVERY REFERRAL PROGRAM</h1>
          <p className='pb-2'> Please find all referral-related terms and conditions   [here](https://instaport delivery.in/referral-inc</p> */}

          <h1 className='text-xl font-semibold mt-4 self-start'>ACTIONS PURSUANT TO THIRD PARTIES</h1>
          <p className='pb-2'>You promise us that, even in the event that we were careless or in default, you will not allow anyone else with an interest in the consignment to file a claim or action against us arising out of the carriage, and that, in the event that such a claim or action is filed, you will indemnify us against the results of the claim or action as well as the costs and expenses we incur in defending it.</p>
          <p className='pb-2'>The terms and conditions of use and/or agreement mentioned above, together with the privacy policy, represent the whole understanding between the user(s) and Instaport delivery regarding the use of the website and the services that Instaport delivery provides. Any previous written or oral agreements are superseded.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>DISCLOSURE</h1>
          <p className='pb-2'>The data on this website and the Instaport delivery app is solely intended for general informational reasons. The information is supplied by Instaport delivery, and although we try to ensure that it is accurate and up to date, we do not make any explicit or implicit representations or warranties regarding the availability, suitability, accuracy, completeness, or reliability of the information, products, services, or related graphics on the website or app for any purpose. You consequently absolutely assume all risk associated with any reliance you may have on such material. We will not be responsible for any loss or harm, including but not limited to indirect or consequential loss or damage, or any loss or damage that results from losing data or revenue that is related to or results from using this website or app.</p>
          <p className='pb-2'>You can link to other websites that are not managed by Instaport delivery using this website or app. The availability, nature, and content of those websites are beyond our control. Any links provided are not meant to be recommendations or endorsements of the content found within.</p>
          <p className='pb-2'>We make every effort to maintain the seamless operation of the portal. Nevertheless, if technical problems outside of our control cause the portal to become momentarily unavailable, Instaport Delivery disclaims all liability and accepts no responsibility for such events.</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>RULES OF GOVERNMENT AND JURISDICTION</h1>
          <p className='pb-2'>Without reference to the doctrine of conflict of laws, the laws of India shall govern and be construed in line with these Terms of Service. Any disagreements, conflicts, or claims arising out of or related to these terms of service or any services we provide in accordance with these terms of service must be exclusively resolved by the courts of [Bengaluru].</p>

          <h1 className='text-xl font-semibold mt-4 self-start'>OFFICER OF GRIEVANCE</h1>
          <p className='pb-2'>The name and contact information of the Nodal/Grievance Officer is provided in accordance with the Consumer Protection (E-Commerce) Rules, 2020. If there are any grievances under the said Rules that may be relevant to the treatment of the said Rules or any grievances pertaining to Services being availed at INSTAPORT DELIVERY, the Authority may direct the request under the said Rules to the grievance officer or at the coordinates listed below.</p>

        </section>
      </div>
      <Footer />
    </section>
  )
}
export default TermsAndConditionsRider